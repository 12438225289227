
import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import {KarsilastirmaliDataTableEntity} from "@/entity/Raporlama/KarsilastirmaliDataTableEntity";
import html2pdf from "html2pdf.js/dist/html2pdf";
import JSZip from "jszip";

@Component
export default class KarsilastirmaDataTable extends Vue {

  @Prop() selectedMuvId: number | null | undefined;
  @Prop() selectedKurumId: number | null | undefined;
  @Prop() muvAndKurumName!:string
  @Prop() baslangicTarihi! :any
  @Prop() bitisTarihi!: any

  cards: {
    number1: string | number;
    number2: string| number;
  }[] = [];

  search: string = '';

  muvekkilDataNumber : number = 0
  allData:any = []

  muvekkilBackControl:boolean = false
  muvekkilForwardControl:boolean = false

  muvekkilAndKurumTitleBack : number = 0
  muvekkilAndKurumTitleForward : number = 1

  headers = [
    { text: 'Aylar/Alanlar', sortable: false, value: 'alanlarAylar',  align: 'center' },
    { text: 'Ocak', value: 'ocak',  align: 'center' },
    { text: 'Şubat', value: 'subat',  align: 'center' },
    { text: 'Mart', value: 'mart',  align: 'center' },
    { text: 'Nisan', value: 'nisan',  align: 'center' },
    { text: 'Mayıs', value: 'mayis',  align: 'center' },
    { text: 'Haziran', value: 'haziran',  align: 'center' },
    { text: 'Temmuz', value: 'temmuz',  align: 'center' },
    { text: 'Ağustos', value: 'agustos',   align: 'center' },
    { text: 'Eylül', value: 'eylul',  align: 'center' },
    { text: 'Ekim', value: 'ekim',  align: 'center' },
    { text: 'Kasım', value: 'kasim',  align: 'center' },
    { text: 'Aralık', value: 'aralik',  align: 'center' },
  ];

  sutunlar: string[] = this.headers.map(header => header.value);

  items : Array<KarsilastirmaliDataTableEntity> =[]

  get sutunlarItems() {
    return [
      { text: 'Tümünü Seç', value: 'selectAll' },
      ...this.headers,
    ];
  }

  @Watch('sutunlar' )
  onSutunlarChange() {
    // Handle Select All option
    if (this.sutunlar.includes('selectAll')) {
      if (this.sutunlar.length === this.headers.length + 1) {
        this.sutunlar = [];
      } else {
        this.sutunlar = this.headers.map(header => header.value);
      }
    }
  }

  changeMuvekkilForward(){
    let newArray = this.allData.slice(0,-1)

    if (newArray.length>this.muvekkilDataNumber){
      ++this.muvekkilDataNumber
      ++this.muvekkilAndKurumTitleForward
      ++this.muvekkilAndKurumTitleBack
    }
    if (this.muvekkilDataNumber>=1){
      this.muvekkilBackControl=true
    }
  }

  changeMuvekkilBack(){

    if (this.muvekkilDataNumber>0){
      --this.muvekkilDataNumber
      --this.muvekkilAndKurumTitleForward
      --this.muvekkilAndKurumTitleBack
    }

    if (this.muvekkilDataNumber==0){
      this.muvekkilBackControl=false
    }
  }

  isTutarTitle(title: string, value: string | number): string | number {
    return title.toLowerCase().includes('tutar') ? this.$helper.tutarStrIstatistik(value) : value + ' Adet';
  }
  alanlarName(){
    return [
      {data:"toplam_genel_toplam_tutari",baslik:"Genel Toplam Tutarı"},
      {data:"acilmamis_genel_toplam_tutari",baslik:"Açılmamış Toplam Tutarı"},
      {data:"acik_genel_toplam_tutari",baslik:"Derdest Toplam Tutarı"},
      {data:"kapali_genel_toplam_tutari",baslik:"Hitam Toplam Tutarı"},
      {data:"toplam_takip_sayisi",baslik:"Toplam Dosya Sayısı"},
      {data:"acilmamis_takip_sayisi",baslik:"Açılmamış Dosya Sayısı"},
      {data:"acik_takip_sayisi",baslik:"Derdest Dosya Sayısı"},
      {data:"kapali_takip_sayisi",baslik:"Hitam Dosya Sayısı"},
      {data:"acilmamis_genel_toplam_tutari",baslik:"Toplam Tahsilat Tutarı"},
      {data:"acilmamis_tahsilat_tutari",baslik:"Açılmamış Tahsilat Tutarı"},
      {data:"acik_tahsilat_tutari",baslik:"Derdest Tahsilat Tutarı"},
      {data:"kapali_tahsilat_tutari",baslik:"Hitam Tahsilat Tutarı"},



    ];
  }

  @Watch('bitisTarihi')
  @Watch('selectedMuvId')
  @Watch('muvekkilDataNumber')
  onPropsChange() {
    if (this.bitisTarihi || (this.selectedMuvId)){
      this.fetchData()

    }
    if ((this.baslangicTarihi && this.bitisTarihi)!=null){
      this.muvekkilForwardControl=true
    }

  }

  async fetchData(){
    try{

      const response:any = await this.$http.post('/api/v1/karsilastirmali-tahsilat-tablosu',{
        "birinci_yil" : this.baslangicTarihi,
        "ikinci_yil" : this.bitisTarihi,
        "muvekkil_id": this.selectedMuvId,
        "kurum_id": this.selectedKurumId,
      });


      this.allData=response

      this.items = response[this.muvekkilDataNumber][this.baslangicTarihi].aylar.map((karsilastirmaDataTableItems:any) =>{
        return karsilastirmaDataTableItems
      })


      this.cards=[
        {
          number1:response[this.muvekkilDataNumber][this.baslangicTarihi],
          number2:response[this.muvekkilDataNumber][this.bitisTarihi]
        }
      ]

    }catch (error) {
      console.error('hata:', error);
    }
  }

  get seciliHeader() {
    return this.headers.filter((header) => this.sutunlar.includes(header.value));
  }

}
